/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.ion-color-azul{
  --ion-color-base: #2845BA;
} 

.ion-color-azul_marino{
  --ion-color-base: #30446A;
} 

.ion-color-naranja{
  --ion-color-base: #FC5B48;
} 

.ion-color-female{
  --ion-color-base: #FF1744;
} 

.ion-color-male{
  --ion-color-base: #2196F3;
} 

.alertCancel{
  background-color: #FC5B48 !important;
  color: white !important;
}
.alertAcept {
  background-color: #3DD77A !important;
  color: white !important;
}

ion-header {
  border: none;
  ion-title {
    color: white;
  }
}

.head {
  width: 100%;
  height: 100%;
  @media only screen and (min-device-width: 660px) {
    background-image: url(assets/img/header-w.png) !important;
  }
  background-image: url(assets/img/header-s.png) !important;
  background-size: cover;
  ion-title {
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
  }
  ion-toolbar {
    --ion-background-color: rgba(253, 253, 253, 0);
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
margin: 0;
}

.segment-button-checked {
  color: #FC5B48 !important;
  font-size: 103%;
  font-weight: bold;
}

ion-segment-button {
  vertical-align: middle;
  font-size: clamp(8px, 3vw, 13px);
  ion-icon {
    padding-right: 5px;
  }
  strong {
    font-weight: bold;
  }
}

.gridDef{
  width: 100%;
  text-align: left;
  font-size: clamp(9px, 3vw, 15px);
  .encabezado {
    ion-col {
      text-align: left;
    }
    --ion-grid-columns: 100;
    background-color: #FC5B48;
    color: #ffffff;
    width: 100% !important;
    font-weight: bold;
  }
  ion-item-sliding {
    ion-item {
    --inner-padding-end: 0px;
    --inner-padding-start: 0px;
    --padding-end: 0px;
    --padding-start: 0px;
    }
  }
  .lista {
    --ion-grid-columns: 100;
    width: 100%;
    font-size: clamp(10px, 3vw, 15px);
    border-bottom: 1px solid #cdd0d4;
    height: auto;
    ion-col {
      border-left: 1px solid #cdd0d4;
      border-right: 1px solid #cdd0d4;
      padding: 0px;
      margin: 0px;
      ion-input, ion-select {
        border: 1px solid #dedede;
        border-radius: 4px;
        margin: 0.5px;
        padding: 2px;
        height: 100%;
      }
    }
    &.hov:hover {
      cursor: pointer;
      border-bottom: 2px solid #FC5B48;
      ion-item {
        --ion-background-color: #fc5a4815;
        // font-weight: bold;
      }
    }
  }
}

.line-input {
  ion-item { 
    --border-color: transparent!important;
    border: 1px solid #dedede;
    border-radius: 4px;

    ion-label {
        color: #000000!important;
        font-weight: 300;
        font-size: 1rem!important;
    }
  }
}

ion-toggle {
  --handle-background-checked: #fff url(assets/img/check.png) no-repeat center / contain;
  @media (min-width: 768px) {
    zoom: 0.8;
  }
  zoom: 0.5;
}

.toogComp {
  zoom: 0.8 !important;
}

.arrowMore {
  width: 11px;
  text-align: left;
  cursor: pointer;
}

.shadow {
  background-color: #f5f5f5;
  &.top { box-shadow: 0px -15px 10px -15px #111; }
  &.bottom { box-shadow: 0px 15px 10px -15px #111; }
  &.left { box-shadow: -15px 0px 10px -15px #111; }
  &.right { box-shadow: 15px 0px 10px -15px #111; }
}

.sel {
  border: 1px solid #dedede;
  border-radius: 4px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  padding: 0px 5px 0px 0px;
  font-size: 14px;
  cursor: pointer;
  width: 100% !important;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.badge-two {
  color: white;
  position: absolute;
  top: 0px;
  right: -5px;
  font-size: 0.6em;
  --padding-start: 3px;
  --padding-end: 3px;
}
